import CrispChat from "components/general/CrispChat";
import FullScreenLoader from "components/general/FullScreenLoader";
import DefaultLayout from "components/layout/DefaultLayout";
import CORoutes from "components/router/CORoutes";
import CustomRouter from "components/router/CustomRouter";
import { SetupStage } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import useRequireAthenaAuth from "hooks/use-require-athena-auth";
import AthenaResultsProcessing from "pages/AthenaResultsProcessing";
import ConnectAthenaPage from "pages/ConnectAthenaPage";
import DiagnosticsAthenaPage from "pages/DiagnosticsAthenaPage";
import SigninupPage from "pages/SigninupPage";
import VerifyCodePage from "pages/VerifyCodePage";
import queryString from "query-string";
import * as React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import DashboardRoutes from "./DashboardRoutes";
import { authPaths } from "./routes";

interface AuthRoutesProps {
  hasUser: boolean;
}

const AthenaRoutes = ({ hasUser }: AuthRoutesProps) => {
  useRequireAthenaAuth();
  return (
    <DefaultLayout>
      <Switch>
        <Route
          path={authPaths.SIGNIN}
          exact
          render={() => <SigninupPage variant="signin" />}
        />
        <Route
          path={authPaths.SIGNUP}
          exact
          render={() => <SigninupPage variant="signup" />}
        />
        <Route path={authPaths.VERIFY_CODE} component={VerifyCodePage} />
        <Route exact path={authPaths.CONNECT} component={ConnectAthenaPage} />
        <Route
          path={authPaths.AWS_RESULTS_PROCESSING}
          component={AthenaResultsProcessing}
        />
        <Route
          path={authPaths.RESULTS_READY}
          component={DiagnosticsAthenaPage}
        />
        {hasUser ? <CrispChat /> : null}
      </Switch>
    </DefaultLayout>
  );
};

const AuthRoutes = ({ hasUser }: AuthRoutesProps) => {
  const location = useLocation();
  const [isAthena, setIsAthena] = useLocalStorageState<boolean>(
    "v_athena",
    false
  );
  const query = {
    ...queryString.parse(location.search),
  };
  const athena = query.athena === "true";

  if (query.athena) {
    if (athena && !isAthena) {
      setIsAthena(true);
    }
    if (!athena && isAthena) {
      setIsAthena(false);
    }
  }

  return !isAthena ? (
    <CORoutes hasUser={!!hasUser} />
  ) : (
    <AthenaRoutes hasUser={!!hasUser} />
  );
};

const Router: React.FC = () => {
  const { user, isLoading } = useAuth();
  const hasAccessToDashboard =
    user?.company?.setupStage === SetupStage.Complete;

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <CustomRouter>
      {hasAccessToDashboard ? (
        <DashboardRoutes />
      ) : (
        <AuthRoutes hasUser={!!user} />
      )}
    </CustomRouter>
  );
};
export default Router;
