/** @jsxImportSource theme-ui */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RenderChildren } from "components/general/Modal";
import * as React from "react";

const configurationItems = [
  {
    title: "Installing and configuring GCloud Command Line tool",
    content: (
      <ul>
        <li>
          <Typography>
            The script is designed to use the GCloud Command Line tool. Please
            make sure you have the latest version of the GCloud Command Line
            Tool installed.
          </Typography>
        </li>
        <li>
          <Typography>
            Link to Google Help docs for installing gcloud -{" "}
            <Link
              href="https://cloud.google.com/sdk/gcloud"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cloud.google.com/sdk/gcloud
            </Link>
          </Typography>
        </li>
      </ul>
    ),
  },
  {
    title: "Setting the gcloud project to be analyzed",
    content: (
      <ul>
        <li>
          <Typography>
            Look up the project ID that you wish to have analyzed. This can be
            found in your gcloud dashboard -{" "}
            <Link
              href="https://console.cloud.google.com/home/dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://console.cloud.google.com/home/dashboard
            </Link>
          </Typography>
        </li>
        <li>
          <Typography>
            Once you have the project ID, you can run the following command in
            the command lin tool - {`"gcloud config set project <project-id>"`}
          </Typography>
        </li>
      </ul>
    ),
  },
  {
    title: "Enabling access to Identity Access Management API",
    content: (
      <Typography>
        Make sure under the{" "}
        <Link
          href="https://console.cloud.google.com/iam-admin/iam"
          target="_blank"
          rel="noopener noreferrer"
        >
          IAM page
        </Link>
        ,{" "}
        {`the "Role" of the account "<PROJECT ID>@cloudservice.gserviceaccount.com" is set to "Owner". This will give the necessary permission for "setIamPolicy" action by the script.`}
      </Typography>
    ),
  },
  {
    title: "Enabling Cloud Deployment Manager",
    content: (
      <ul>
        <li>
          <Typography>
            The script will create a deployment template to be executed. In
            order to execute deployment templates, the Cloud Deployment Manager
            function will need to be enabled.
          </Typography>
        </li>
        <li>
          <Typography>
            {`Follow the link and check to see if the Cloud Deployment Manager is
            enabled for the project. If not, click on the “Enable” button.`}
          </Typography>
        </li>
        <li>
          <Typography>
            <Link
              href="https://console.cloud.google.com/marketplace/product/google-cloud-platform/cloud-deployment-manager"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://console.cloud.google.com/marketplace/product/google-cloud-platform/cloud-deployment-manager
            </Link>
          </Typography>
        </li>
      </ul>
    ),
  },
  {
    title: "Enabling Deployment Manager API",
    content: (
      <ul>
        <li>
          <Typography>
            The script will require access to the Deployment Manager API. The
            deployment template will be executed using the API.
          </Typography>
        </li>
        <li>
          <Typography>
            {`Follow the link and check to see if the Deployment Manager API is
            enabled. If not, click on the “Enable” button.`}
          </Typography>
        </li>
        <li>
          <Typography>
            <Link
              href="https://console.cloud.google.com/marketplace/product/google/deploymentmanager.googleapis.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://console.cloud.google.com/marketplace/product/google/deploymentmanager.googleapis.com
            </Link>
          </Typography>
        </li>
      </ul>
    ),
  },
  {
    title: "Account Roles",
    content: (
      <ul>
        <li>
          <Typography>
            {`Key account roles will need to be granted to the account used for
            executing the script. Please log into the Account Roles page and
            ensure the login ID has the following roles:`}
          </Typography>
          <ul>
            <li>
              <Typography>Service Account Admin</Typography>
            </li>
            <li>
              <Typography>Deployment Manager Type Editor</Typography>
            </li>
            <li>
              <Typography>Owner</Typography>
            </li>
          </ul>
        </li>
        <li>
          <Typography>
            {`Follow the link and check to see if the Cloud Deployment Manager is
            enabled for the project. If not, click on the “Enable” button.`}
          </Typography>
        </li>
        <li>
          <Typography>
            <Link
              href="https://console.cloud.google.com/iam-admin/iam"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://console.cloud.google.com/iam-admin/iam
            </Link>
          </Typography>
        </li>
      </ul>
    ),
  },
];

const GCPTroubleShootingView: RenderChildren = () => {
  return (
    <>
      <Typography variant="h4" sx={{ color: "#EF7B2E", mb: 3 }}>
        Configuration
      </Typography>
      {configurationItems.map(({ title, content }, index) => {
        return (
          <Accordion key={index.toString()}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                ul: {
                  pl: "12px",
                },
              }}
            >
              {content}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
GCPTroubleShootingView.displayName = "GCPTroubleShootingView";
export default GCPTroubleShootingView;
