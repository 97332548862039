import React from "react";
import useRouter from "./use-router";

export default function useSaveQueryParamToStorage(param: string) {
  const { query } = useRouter();
  const dataFromQuery = query[param];

  React.useEffect(() => {
    if (dataFromQuery) {
      try {
        const dataToStore =
          typeof dataFromQuery === "string"
            ? dataFromQuery
            : JSON.stringify(dataFromQuery);
        localStorage.setItem(`query-${param}`, dataToStore);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, [dataFromQuery, param]);
}
