import { useQuery } from "react-query";
import env from "utils/env";

export const fetchTemplate = () => {
  return fetch(env("REACT_APP_AWS_SCRIPT_BUCKET"))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error(res.statusText);
    })
    .then((response) => {
      return JSON.stringify(response, null, 2);
    })
    .catch((error) => {
      return error.message;
    });
};

export const useTemplateQuery = () =>
  useQuery<string, string>("script", fetchTemplate);
