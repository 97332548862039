/** @jsxImportSource theme-ui */
import * as React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from "recharts";

interface ChartData {
  name: string;
  value: number | undefined;
}
interface Props {
  data: Array<ChartData>;
}

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}
const RADIAN = Math.PI / 180;

const renderCustomizedLabel: React.FC<CustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontWeight: 600,
        fontSize: 18,
      }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const AthenaPieChart: React.FC<Props> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={300} height={300}>
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={140}
          fill="#8884d8"
          label={renderCustomizedLabel}
          startAngle={-270}
          endAngle={90}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell
              key={`piecell-${index}`}
              fill={entry.name === "Total" ? "#07e319" : "red"}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
export default AthenaPieChart;
