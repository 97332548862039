import { authPaths } from "components/router/routes";
import { SetupStage, UserFFragment } from "generated/graphql";

export const getNextOnboardingPath = (
  currentPathName: string,
  user: UserFFragment | null
) => {
  // anonymous user have access to sign in, sign up and verify pages
  if (!user) {
    if (
      currentPathName === authPaths.SIGNIN ||
      currentPathName === authPaths.SIGNUP ||
      currentPathName === authPaths.VERIFY_CODE
    ) {
      return null;
    }
    return authPaths.SIGNIN;
  }
  // Users should stick to their setup stage
  if (user.company?.setupStage) {
    const currentSetupStage = user.company.setupStage;
    if (
      currentSetupStage === SetupStage.Script ||
      currentSetupStage === SetupStage.Role
    ) {
      if (
        !currentPathName.startsWith(authPaths.CONNECT) &&
        !currentPathName.startsWith(authPaths.VERIFY_SETUP)
      ) {
        const gcpEncodedJson = localStorage.getItem("query-json");
        if (gcpEncodedJson) {
          return `${authPaths.VERIFY_SETUP}/GCP?json=${gcpEncodedJson}`;
        }
        return authPaths.CONNECT;
      }
      return null;
    }

    if (currentSetupStage === SetupStage.Region) {
      if (currentPathName === authPaths.CHOOSE_REGIONS) {
        return null;
      }
      return authPaths.CHOOSE_REGIONS;
    }
    if (currentSetupStage === SetupStage.Processing) {
      if (currentPathName === authPaths.AWS_RESULTS_PROCESSING) {
        return null;
      }
      return authPaths.AWS_RESULTS_PROCESSING;
    }
    if (currentSetupStage === SetupStage.Results) {
      if (currentPathName === authPaths.DIAGNOSTIC_DASHBOARD) {
        return null;
      }
      return authPaths.DIAGNOSTIC_DASHBOARD;
    }
  }

  // eslint-disable-next-line no-console
  console.error(`Why we didn't return here?`, { user, currentPathName });
  return null;
};
