import CompanyDetailsPage from "pages/admin/CompanyDetailsPage";
import BusinessUnitPage from "pages/admin/BusinessUnitPage";
import UsersPage from "pages/admin/UsersPage";
import { Redirect, Route, Switch } from "react-router-dom";
import ClientManagementPage from "pages/admin/ClientManagementPage";
import BusinessUnitDetailsPage from "pages/admin/BusinessUnitDetailsPage";
import AdminNav from "components/admin/AdminNav";
import * as React from "react";
import useAnalytics from "hooks/use-analytics";
import DashboardLayout from "components/layout/DashboardLayout";
import DashboardPage from "pages/DashboardPage";
import OpportunitiesPage from "pages/OpportunitiesPage";
import OpportunityPage from "pages/OpportunityPage";
import { adminPaths } from "./routes";

const MAIN_ADMIN_ROUTES = [
  {
    title: "Company Details",
    path: adminPaths.COMPANY_DETAILS,
    component: CompanyDetailsPage,
  },
  {
    title: "Business Units",
    path: adminPaths.BUSINESS_UNITS,
    component: BusinessUnitPage,
  },
  {
    title: "Users",
    path: adminPaths.USERS,
    component: UsersPage,
  },
];

const AdminRoutes = () => (
  <Route path="/admin">
    <Switch>
      <Route path="/admin" exact component={ClientManagementPage} />
      <Route
        path={`${adminPaths.BUSINESS_UNITS}/:businessUnit/:account`}
        component={BusinessUnitDetailsPage}
      />
      <AdminNav routes={MAIN_ADMIN_ROUTES}>
        {MAIN_ADMIN_ROUTES.map(({ path, component: Component }) => {
          return <Route key={path} path={path} exact component={Component} />;
        })}
      </AdminNav>
    </Switch>
  </Route>
);

const DashboardRoutes: React.FC = () => {
  useAnalytics();
  return (
    <DashboardLayout>
      <Switch>
        <Route path="/" exact component={DashboardPage} />
        <Route path="/opportunities" exact component={OpportunitiesPage} />
        <Route path="/opportunities/:id" exact component={OpportunityPage} />
        <AdminRoutes />

        <Redirect to="/" />
      </Switch>
    </DashboardLayout>
  );
};

export default DashboardRoutes;
