import CrispChat from "components/general/CrispChat";
import DefaultLayout from "components/layout/DefaultLayout";
import { authPaths } from "components/router/routes";
import { Cloud } from "generated/graphql";
import useAnalytics from "hooks/use-analytics";
import useRequireAuth from "hooks/use-require-auth";
import useSaveQueryParamToStorage from "hooks/use-save-query-param-to-storage";
import AWSSetupResults from "pages/AWSSetupResults";
import ChooseRegionsPage from "pages/ChooseRegions";
import ConnectAwsPage from "pages/ConnectAwsPage";
import ConnectGcpPage from "pages/ConnectGcpPage";
import ConnectPage from "pages/ConnectPage";
import DiagnosticDashboardPage from "pages/DiagnosticDashboardPage";
import SigninupPage from "pages/SigninupPage";
import VerifyCodePage from "pages/VerifyCodePage";
import VerifySetupAWSPage from "pages/VerifySetupAWSPage";
import VerifySetupGCPPage from "pages/VerifySetupGCPPage";
import * as React from "react";
import { Route, Switch } from "react-router-dom";

interface CORoutesProps {
  hasUser: boolean;
}
const CORoutes: React.FC<CORoutesProps> = ({ hasUser }) => {
  useSaveQueryParamToStorage("json");
  useAnalytics();
  useRequireAuth();
  return (
    <DefaultLayout>
      <Switch>
        <Route
          path={authPaths.SIGNIN}
          exact
          render={() => <SigninupPage variant="signin" />}
        />
        <Route
          path={authPaths.SIGNUP}
          exact
          render={() => <SigninupPage variant="signup" />}
        />
        <Route path={authPaths.VERIFY_CODE} component={VerifyCodePage} />

        <Route exact path={authPaths.CONNECT} component={ConnectPage} />
        <Route
          path={`${authPaths.CONNECT}/${Cloud.Aws}`}
          component={ConnectAwsPage}
        />
        <Route
          path={`${authPaths.CONNECT}/${Cloud.Gcp}`}
          component={ConnectGcpPage}
        />

        <Route
          path={`${authPaths.VERIFY_SETUP}/${Cloud.Aws}`}
          component={VerifySetupAWSPage}
        />
        <Route
          path={`${authPaths.VERIFY_SETUP}/${Cloud.Gcp}`}
          component={VerifySetupGCPPage}
        />

        <Route
          path={authPaths.AWS_RESULTS_PROCESSING}
          component={AWSSetupResults}
        />
        <Route path={authPaths.CHOOSE_REGIONS} component={ChooseRegionsPage} />
        <Route
          path={authPaths.DIAGNOSTIC_DASHBOARD}
          component={DiagnosticDashboardPage}
        />
      </Switch>
      {hasUser ? <CrispChat /> : null}
    </DefaultLayout>
  );
};

export default CORoutes;
