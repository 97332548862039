/** @jsxImportSource theme-ui */
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import format from "date-fns/format";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import React from "react";
import env from "utils/env";

interface DisplayTemplateProps {
  template: string | undefined;
  isLoading: boolean;
  error: unknown;
}
const replaceTemplateValues = (templateString: string | undefined): string => {
  if (!templateString) return "";
  const InfrastructureAccount = env("REACT_APP_INFRASTRUCTURE_ACCOUNT");
  const jsonTemplate = JSON.parse(templateString);
  jsonTemplate.Parameters.InfrastructureAccount.Default = InfrastructureAccount;

  jsonTemplate.Parameters.CrossAccountRoleName.Default = `virtasant-read-only-${format(
    new Date(),
    "MM-dd-yy"
  )}`;

  jsonTemplate.Resources.ReadOnlyRole.Properties.AssumeRolePolicyDocument.Statement[0].Principal.AWS = {
    "Fn::Sub": `arn:aws:iam::${InfrastructureAccount}:root`,
  };
  return JSON.stringify(jsonTemplate, null, 1);
};
const TemplateTypography: React.FC<{ template: string }> = ({ template }) => (
  <Typography
    data-testid="template-container"
    sx={{
      px: 3,
      py: 3,
      overflow: "scroll",
      maxWidth: [300, "100%"],
      display: "block",
      height: "100%",
    }}
    component="pre"
  >
    {template}
  </Typography>
);
const MemoizedTemplate = React.memo(TemplateTypography);

const DisplayTemplate: React.FC<DisplayTemplateProps> = ({
  template,
  isLoading,
  error,
}) => {
  const [fullscreen, setFullscreen] = React.useState<boolean>(false);
  const replacedTemplate = React.useMemo(
    () => replaceTemplateValues(template),
    [template]
  );
  if (error)
    return (
      <Box
        height="224px"
        maxWidth={818}
        mt={6}
        display="flex"
        sx={{
          backgroundColor: "#E9E9EA",
        }}
      >
        <Box mx="auto" my="auto">
          <Typography>
            There was an error while fetching the json script
          </Typography>
        </Box>
      </Box>
    );
  return (
    <Box>
      <Dialog
        open={fullscreen}
        onClose={() => setFullscreen(false)}
        maxWidth="md"
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h5">Script</Typography>
            <IconButton onClick={() => setFullscreen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ backgroundColor: "#E9E9EA" }}>
            <MemoizedTemplate template={replacedTemplate} />
          </Box>
        </DialogContent>
      </Dialog>

      <Box
        height={150}
        mt={2}
        sx={{
          backgroundColor: "#E9E9EA",
          position: "relative",
          maxWidth: [, 818],
          width: "100%",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: [0, "1rem"],
            top: 0,
          }}
          onClick={() => setFullscreen(true)}
        >
          <FullscreenIcon color="primary" />
        </IconButton>
        {isLoading ? (
          <Box mx="auto" my="auto">
            <CircularProgress />
          </Box>
        ) : (
          <MemoizedTemplate template={replacedTemplate} />
        )}
      </Box>
    </Box>
  );
};

export default DisplayTemplate;
