/** @jsxImportSource theme-ui */
import { Grid, Link, Typography } from "@material-ui/core";
import { ReactComponent as IconUploading } from "assets/icons/icon-uploading.svg";
import OnboardingBar from "components/navigation/OnboardingBar";
import { authPaths } from "components/router/routes";
import { AthenaStatus, useAthenaResultsQuery } from "generated/graphql";
import useRouter from "hooks/use-router";
import * as React from "react";

const AthenaResultsProcessing: React.FC = () => {
  const { push } = useRouter();
  useAthenaResultsQuery(
    {},
    {
      onSuccess: (data) => {
        if (data.AthenaResults?.status === AthenaStatus.Ready) {
          push(authPaths.RESULTS_READY);
        }
      },
    }
  );

  return (
    <Grid
      sx={{
        background: `linear-gradient(288.06deg, #5F9EEE 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        height: "100%",
        minHeight: "100vh",
        px: 20,
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
        }}
      />
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        sx={{
          mt: [, 50],
        }}
      >
        <Grid container justify="center" alignContent="center">
          <Grid
            item
            sx={{
              mr: [, 132],
              mt: [, 20],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconUploading sx={{ width: [80, "unset"], mr: [15, 0] }} />
            <Typography
              variant="h1"
              sx={{
                display: [, "none"],
                color: "white",
                flexBasis: "190px",
                fontSize: 28,
              }}
            >
              Processing your results
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              sx={{
                display: ["none", "block"],
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 80,
                lineHeight: ["32px", "88px"],
                letterSpacing: "-2px",
                maxWidth: 490,
              }}
            >
              Processing your results
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: [, 24],
                color: "white",
                fontFamily: "Montserrat",
                maxWidth: 615,
              }}
            >
              Should be ready shortly...
            </Typography>

            <Typography
              variant="body1"
              sx={{
                display: "inline-flex",
                mt: 40,
                ml: [40, 0],
                fontFamily: "Montserrat",
                color: "white",
                alignItems: "center",
              }}
            >
              Need assistance
              <Link
                href="mailto:codiagnostic@virtasant.com"
                rel="noopener noreferrer"
                target="_blank"
                sx={{
                  ml: "1rem",
                  fontWeight: 400,
                  color: "white",
                  textDecoration: "underline",
                }}
              >
                Contact Support
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
AthenaResultsProcessing.displayName = "AthenaResultsProcessing";
export default AthenaResultsProcessing;
