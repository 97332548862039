import * as React from "react";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import LocationDisplay from "components/general/LocationDisplay";

export const InitialTestRouteContext = React.createContext("/");

const CustomRouter: React.FC<{ CustomRouterProps?: string }> = ({
  children,
}) => {
  const initialTestRoute = React.useContext(InitialTestRouteContext);
  if (process.env.NODE_ENV === "test") {
    return (
      <MemoryRouter initialEntries={[initialTestRoute]}>
        <LocationDisplay />
        {children}
      </MemoryRouter>
    );
  }
  return <BrowserRouter>{children}</BrowserRouter>;
};

export default CustomRouter;
